<template>
  <section :class="['base-faq', { open: isOpen }]" v-bind="attrs">
    <div ref="headerRef" class="base-faq-flex">
      <h4 class="font--b4--sb">{{ props.title }}</h4>
      <button
        :class="['plusminus', { active: isOpen }]"
        @click="toggleDetails"
      />
    </div>
    <div ref="detailsRef" class="base-faq-details">
      <p
        v-for="point in props.details"
        :key="point"
        class="base-faq-details-text font--b5--r"
      >
        {{ point }}
      </p>
    </div>
  </section>
</template>

<script setup lang="ts">
import { useAttrs } from 'vue';

interface Props {
  title: string;
  details: string[];
}

const props = withDefaults(defineProps<Props>(), {});

const attrs = useAttrs();
const isOpen = ref(false);
const headerRef = ref<HTMLElement | undefined>();
const detailsRef = ref<HTMLElement | undefined>();

const headerHeight = computed(() => {
  if (headerRef.value && headerRef.value instanceof HTMLElement) {
    return headerRef.value.offsetHeight + 32 + 'px'; //header + padding
  }
});

const detailsHeight = computed(() => {
  if (
    detailsRef.value &&
    headerRef.value &&
    detailsRef.value instanceof HTMLElement &&
    headerRef.value instanceof HTMLElement
  ) {
    return (
      detailsRef.value.offsetHeight +
      headerRef.value.offsetHeight +
      16 +
      32 +
      'px'
    ); //details + header + gap + padding
  }
});

function toggleDetails() {
  isOpen.value = !isOpen.value;
}
</script>

<style scoped lang="scss">
.base-faq {
  position: relative;
  width: 100%;
  max-width: 100%;
  min-width: 345px;
  height: auto;
  max-height: v-bind(headerHeight);
  display: flex;
  flex-direction: column;
  gap: 16px;
  overflow: hidden;
  transition: max-height 0.3s ease-in-out, background-color 0.3s ease-in-out,
    border 0.3s ease-in-out;
  padding: 16px;
  border-radius: 24px;
  background: var(--monochromes-grey-500);
  border: 1px solid transparent;
  &-flex {
    max-width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 12px;
    .plusminus {
      flex-shrink: 0;
      position: relative;
      width: 32px;
      height: 32px;
      border-radius: 40px;
      cursor: pointer;
      padding: 0;
      margin: 0;
      outline: none;
      background-color: var(--monochromes-grey-10012);
      border: none;
      &.active {
        &:before {
          transform: translate(-50%, -50%) rotate(-90deg);
          opacity: 0;
        }
        &:after {
          transform: translate(-50%, -50%) rotate(0);
        }
      }

      &:before,
      &:after {
        content: '';
        display: block;
        background-color: var(--monochromes-white);
        position: absolute;
        top: 50%;
        left: 50%;
        transition: 0.35s;
        width: 13px;
        height: 2px;
        border-radius: 10px;
      }

      &:before {
        transform: translate(-50%, -50%);
      }

      &:after {
        transform: translate(-50%, -50%) rotate(90deg);
      }
    }
  }
  h4 {
    word-break: normal;
    cursor: default;
  }
  &-details {
    word-break: normal;
    cursor: default;
    padding-left: 23px;

    &-text {
      position: relative;
      padding-bottom: 18px;
      &:before {
        content: '';
        position: absolute;
        height: 4px;
        width: 4px;
        border-radius: 3px;
        background-color: var(--monochromes-grey-200);
        left: -14px;
        top: 8px;
      }
    }
  }
}
.open {
  background: var(--monochromes-grey-400);
  border-color: var(--monochromes-grey-10012);
  max-height: v-bind(detailsHeight);
}
</style>
