<script setup lang="ts"></script>

<template>
  <svg
    width="305"
    height="12"
    viewBox="0 0 305 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="6" cy="6" r="6" fill="#ABFE06" />
    <path
      d="M24 6H304"
      stroke="#ABFE06"
      stroke-width="2"
      stroke-linecap="round"
    />
  </svg>
</template>

<style scoped lang="scss"></style>
