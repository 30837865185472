<script setup lang="ts"></script>

<template>
  <div class="marquee">
    <div class="marquee-inner" aria-hidden="true">
      <span class="marquee-content">Multichain</span>
      <span class="marquee-content">Anonymous</span>
      <span class="marquee-content">Safe</span>
      <span class="marquee-content">Multichain</span>
      <span class="marquee-content">Anonymous</span>
      <span class="marquee-content">Safe</span>
      <span class="marquee-content">Multichain</span>
      <span class="marquee-content">Anonymous</span>
      <span class="marquee-content">Safe</span>
      <span class="marquee-content">Multichain</span>
      <span class="marquee-content">Anonymous</span>
      <span class="marquee-content">Safe</span>
    </div>
  </div>
</template>

<style scoped lang="scss">
.marquee {
  position: relative;
  overflow: hidden;
  background-color: var(--monochromes-grey-400);
}

.marquee-inner {
  width: fit-content;
  display: flex;
  position: relative;
  animation: marquee 10s linear infinite;
}

.marquee-content {
  color: var(--monochromes-grey-200);
  font-size: 60px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  padding: 20px 40px;
}

@keyframes marquee {
  0% {
    transform: translate(20%, 0);
  }

  100% {
    transform: translate(-5%, 0);
  }
}

@media (max-width: 1450px) {
  .marquee-content {
    font-size: 40px;
    padding: 16px 40px;
  }
}

@media (max-width: 1280px) {
  .marquee-content {
    font-size: 24px;
    padding: 20px 12px;
  }
}
</style>
